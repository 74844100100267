<template>
  <div>
    <BudgetsForm
      @validate="validate"
      :budget="budget"
      :valid="isFormValid"
      @save="createBudget"
      :type="'create'"
      ref="form"
    />

    <v-snackbar
      v-model="showAlert"
      :timeout="-1"
      color="red darken-4"
      elevation="28"
      class="custom-snackbar"
      centered
    >
      <v-icon
        size="38"
        color="white"
        class="mr-2"
        >mdi-alert-circle</v-icon
      >
      <span class="snackbar-text">
        Atenção! É necessário cadastrar pelo menos um produto para salvar o
        orçamento.
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          text
          class="close-btn"
          @click="showAlert = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import beforeLeaveRouteModal from '@/mixins/beforeLeaveRouteModal.js'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'

export default {
  components: {
    BudgetsForm: () => import('./BudgetsForm'),
  },
  mixins: [beforeLeaveRouteModal, checkProfileRoutePermission],
  data: () => ({
    isFormValid: true,
    askToLeave: true,
    toSendProducts: [],
    budgetProducts: [],
    showAlert: false,
    budget: {
      type: 'budget',
      client_id: null,
      client_contact_id: null,
      seller_id: null,
      company_id: null,
      status: null,
      validate_budget: null,
      deadline: null,
      shipping: null,
      description: null,
      sendind_date: null,
      products: [],
    },
  }),
  created() {
    this.$store.commit('resetProducts')
    if (this.$store.state.budgetClientId != null) {
      this.budget.client_id = this.$store.state.budgetClientId
      this.budget.seller_id = this.$store.state.budgetClientSellerId
    }
  },
  methods: {
    validate(value) {
      this.isFormValid = value
    },
    createBudget() {
      if (this.budget.products.length === 0) {
        this.showAlert = true
        return
      }

      this.askToLeave = false
      let total =
        this.$store.state.budgetTotal.length > 0
          ? this.$store.state.budgetTotal.reduce((acc, curr) => acc + curr)
          : 0
      let orderTotal =
        this.$store.state.orderTotal.length > 0
          ? this.$store.state.orderTotal.reduce((acc, curr) => acc + curr)
          : 0
      let canApprove = this.$store.state.budgetApproval.reduce(
        (acc, curr) => acc + curr
      )
      canApprove = canApprove > 0 ? false : true

      if (this.$store.state.productsToRemove.length > 0) {
        this.$store.state.budgetProducts.map((budget_product) => {
          this.$store.state.productsToRemove.map((to_remove) => {
            if (
              budget_product.product_id == to_remove.product_id &&
              budget_product.color_id == to_remove.color_id
            ) {
              //
            } else {
              this.toSendProducts.push(budget_product)
            }
          })
        })
      } else {
        this.toSendProducts = this.$store.state.budgetProducts
      }

      this.$api
        .post('budgets', {
          ...this.budget,
          products: this.toSendProducts,
          total_cost: total,
          can_approve: canApprove,
          subtotal: parseFloat(orderTotal.toFixed(2)),
        })
        .then(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'Orçamento cadastrado com sucesso',
            confirm: () => {
              this.$router.push({ name: 'budgets' })
            },
          })
          this.$store.commit('resetTotal')
          this.$store.commit('resetApproval')
        })
        .catch(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops! Falha ao tentar cadastrar este Orçamento',
            caption: 'Verifique se os campos estão preenchidos corretamente.',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
        .finally(() => {
          this.$store.commit('resetProducts')
        })
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.askToLeave) {
      this.openModal(
        () => {
          next()
        }, // Caso o modal seja confirmado
        () => {
          next(false)
        } // Caso o modal seja negado
      )
    } else {
      next()
    }
  },
}
</script>

<style scoped>
.custom-snackbar {
  width: 80%;
  max-width: 800px;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  position: fixed;
}
</style>
